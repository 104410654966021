import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStateProvider from './store/GlobalStateProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { disableLoggers } from './helpers/common';
import { SITE_AT } from './helpers/utils';
import { APP_ENV } from './helpers/constants';

let DEBUG = false;
if (SITE_AT !== APP_ENV.LIVE) {
  DEBUG = true;
}

if (!DEBUG) {
  disableLoggers();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GlobalStateProvider>
    <App />
  </GlobalStateProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
