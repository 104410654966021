const TextDictionary = {
  common: {
    _1: 'Save Changes',
  },
  signup: {
    _1: 'Who would you <br /> like to hire<br /> today?',
  },
  postJob: {
    _1: 'Hide Salary',
    _2: 'Who would you like to hire today?',
    _3: 'Enter Job Title',
  },
  empuploadcv: {
    _1: 'Your CVs are being uploaded.<br />Thank you for your patience.',
    _2: 'Up to 100 CVs can be uploaded at a time.<br /> Only PDFs and Word documents are supported!',
    _3: 'Our AI is analyzing your CVs. Thanks for your patience. Please wait.',
    _4: 'Career summaries are being generated for your CVs.',
    _5: 'Our AI is analyzing your CVs. Thanks for your patience. ',
  },
  empappliant: {
    _1: 'Below are the CVs that matched your search criteria.',
    _2: 'Shortlisted',
    _3: 'Shortlist',
    _4: 'Not Selected',
    _5: 'Remove from Shortlist',
  },
  empjobdetail: {
    _1: 'Link Copied Successfully!',
  },
  empcommon: {
    _1: 'Back to dashboard',
    _2: 'Upload CVs',
  },
  empjobedit: {
    placeholders: {
      _1: 'Enter Job Title',
      _2: 'Company Name',
      _3: 'Yes or No',
      _4: '20',
      _5: '5 Years',
      _6: 'Enter Description',
      _7: 'Enter City',
      _8: '/ Month',
      _9: 'Add New Skill',
    },
    _1: 'Who would you like to hire today?',
    _2: 'What is the name of your company?',
    _3: 'Does this position manage other employees?',
    _4: 'How many employees work under this position?',
    _5: "What's the minimum experience required for this job?",
    _6: 'Description',
    _7: 'Which city is this position based in?',
    _8: "What's the maximum budget for this position?",
    _9: 'Add New Skills',
  },
  testBuilder: {
    mcqTest: {
      _1: 'Create a customized online assessment test based on your job description and send it to applicants with a click. RozeeGPT evaluates the tests and provides instant graded results.',
      _2: 'Generate MCQ Test',
      _3: 'Send MCQ Test',
      _4: 'Please proceed with the test to complete your application process.<br/>  An email containing test instructions has been sent to you as well.',
      _5: 'Your Test has been sent to the candidate, but it has not been attempted yet.',
      _6: 'MCQ Test Pending',
      _7: 'You are ready to send the test to the candidate. Click the button below to send the test to the candidate.',
      _8: 'Invite all applicants for mcq test.',
      _9: 'MCQ Test Started',
      _10: 'The candidate has begun the MCQ test.',
    },
    codingTest: {
      _1: "Want to test your candidates' coding skills? Use RozeeGPT's AI engine to create coding tests and get real-time results and analysis in major coding languages with just a few clicks.",
      _2: 'Create Coding Test Live',
      _3: 'You have not created the coding test yet. Get started by creating a customized coding test to evaluate potential candidates.',
      _4: 'Coding Test Pending',
      _5: 'You are ready to send the coding test to the candidate. Click the button below to send the video interview to the candidate.',
      _6: 'Send Coding Test',
      _7: 'Your Test has been sent to the candidate, but it has not been attempted yet',
      _8: 'Coding Test Started',
      _9: 'The candidate has dived into the coding test!',
      _10: 'Please proceed with a coding test to complete your application process. <br/>  An email containing test instructions has been sent to you as well.',
    },
    videoInterview: {
      _1: 'Please proceed with a video interview to complete your application process. <br/>  An email containing interview instructions has been sent to you as well.',
      _2: "The candidate's video interview invite has been sent but not yet opened.",
      _3: 'You have not created the video interview yet. Get strated by creating a customized video interview to evaluate potential candidates.',
      _4: 'You are ready to send the video interview to the candidate. Click the button below to send the video interview to the candidate',
      _5: 'Video Interview Pending',
      _6: 'Create Video Interview',
      _7: 'Send Video Interview',
      _8: 'Add Video Interview',
      _9: 'The candidate has jumped into the video interview!',
      _10: 'Video Interview Started',
    },
  },
  empCreateVideoInterview: {
    _1: 2,
  },
  templates: {
    templateplaceholders: {
      personalInformation: {
        _1: 'Your name here',
        _2: 'Your tagline here',
        _3: 'Your email here',
        _4: 'Your mobile here',
        _5: 'Your address here',
        _6: 'Your website here',
      },
      employment: {
        _1: 'Start date',
        _2: 'End date',
        _3: 'Enter company name',
        _4: 'Enter job title',
        _5: 'Enter city',
      },
    },
  },
};

export default TextDictionary;
